import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AppState, MenuItem } from 'src/utils/shared-types'
import { initValues } from './initial-values'
import { labelsKeys } from '../../form/default-values'
import {
  DateTimeInput,
  IconButton,
  IconSearch,
  Input,
  Select,
  SelectHandler,
  SelectOption,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import { emptyItem } from 'src/components/adminForms/initials'
import { states } from 'src/utils/states'
import ShoppingCart from '../../shopping-cart/shopping-cart'

import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'
import { api } from 'src/utils/api'
import { continueReplacementSchema } from './validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { usePaymentContext } from 'src/hooks/use-payment-context'
import { PaymentsMethodMfeExposedApi } from '@ftdr/payment-method-micro-frontend'
import PaymentsForm from '../../stripe-sub-form/stripe-sub-form'
import { usePayments } from 'src/hooks/use-temp-payments'

import { Agreements } from '../../agreements/agreements'
import { compareEqual } from '../../utils/helpers'
import SkuSearch from '../../sku-search/sku-search'
import { useFormsContext } from 'src/hooks/use-forms-context'
import { useSelector } from 'react-redux'
import { useGetVendors } from 'src/hooks/use-get-vendors'
import CartAvailabilityModal from '../../cart-availability-modal/cart-availability-modal'
import usePaymentOptions from 'src/hooks/use-payment-options'
import { regexDigit } from 'src/utils/validation-utils'

interface IForm {
  contractID: string
  tenantAbbreviation: MenuItem
  firstName: string
  lastName: string
  billingState: MenuItem
  vendor: MenuItem
  sku: string
  date: Date | null
  agreements: boolean
  amount?: string
}

const init = initValues

const OutrightPaymentForm = () => {
  const { info: dispatchSearchInfo } = useSelector(
    (state: AppState) => state.survey
  )
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { isVendorsLoading } = useGetVendors()
  const { submitStripePayment } = usePayments()
  const { status: paymentStatus, errorMsg } = usePaymentContext()
  const submitPaymentRef: RefObject<PaymentsMethodMfeExposedApi> = useRef(null)

  const [isContractInfoLoading, setIsContractInfoLoading] = useState<boolean>(
    false
  )

  const [replacementError, setReplacementError] = useState<string>('')
  const [initialTenantAbbreviation, setInitialTenantAbbreviation] = useState<
    MenuItem
  >(emptyItem)
  const [
    isCartAvailabilityModalOpen,
    setIsCartAvailabilityModalOpen,
  ] = useState<boolean>(false)

  const {
    resetShoppingCartData,
    isShoppingCartEmpty,
    shoppingCartData,
    total,
    tax,
    hasCartUnavailableItems,
    lastSku,
    setTenant,
  } = useShoppingCartContext()
  const { counter, formType } = useFormsContext()

  const {
    getOptions,
    findTenant,
    findVendor,
    findTenantByLabel,
    findBillingState,
  } = usePaymentOptions()

  const { status } = usePaymentContext()

  const {
    watch,
    reset,
    resetField,
    setValue,
    clearErrors,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForm>({
    mode: 'onTouched',
    resolver: yupResolver(continueReplacementSchema),
    defaultValues: initValues,
  })

  const sku = watch('sku')
  const contractID = watch('contractID')
  const { id: vendor } = watch('vendor')
  const firstName = watch('firstName')
  const lastName = watch('lastName')
  const billingState = watch('billingState.id')
  const tenantAbbreviation = watch('tenantAbbreviation')

  const submitForm = async (data: IForm, verify: boolean = false) => {
    if (verify && hasCartUnavailableItems) {
      setIsCartAvailabilityModalOpen(true)
      return
    }
    let cart = shoppingCartData.map(({ item, price, unitTax }) =>
      unitTax
        ? {
            ...item,
            ...price,
            unitTax,
          }
        : {
            ...item,
            ...price,
          }
    )

    const dataEntry = {
      cartItems: cart,
      taxDetail: tax,
    }
    const postData =
      tenantAbbreviation.id === 'PC'
        ? {
            ...data,
            paymentType: formType.id || '',
            amount: data.amount!,
            billingState: data.billingState.id,
            vendor: data.vendor.id,
            dispatchID: '',
            orderID: '',
          }
        : {
            ...data,
            paymentType: formType.id || '',
            cart: dataEntry,
            amount: `${total / 100}`,
            billingState: data.billingState.id,
            vendor: data.vendor.id,
            dispatchID: '',
            orderID: '',
          }

    submitStripePayment(submitPaymentRef, postData)
  }
  const setAgreements = useCallback((v: boolean) => {
    setValue('agreements', v)
    setValue('amount', '')
    v && clearErrors('agreements')
    //eslint-disable-next-line
  }, [])

  const setReplacementInfo = async () => {
    reset({ ...initValues, contractID })
    resetShoppingCartData()
    clearErrors('agreements')
    if (!contractID) return
    setIsContractInfoLoading(true)
    try {
      const info = await api.getContractInfo(contractID)
      const customerStateId =
        info?.contractInfos?.[0]?.coveredPropertyAddress?.address?.state || ''
      const customerState =
        states.find((state) => state.id === customerStateId) || emptyItem
      const customerFirstName =
        info?.contractInfos?.[0]?.customerDetails?.[0].firstName || ''
      const customerLastName =
        info?.contractInfos?.[0]?.customerDetails?.[0].lastName || ''
      if (!info || info?.contractInfos?.length === 0) {
        setReplacementError('Not found')
        setIsContractInfoLoading(false)
        reset({ ...initValues, contractID })
        return
      }

      setReplacementError('')
      setValue('firstName', customerFirstName)
      setValue('lastName', customerLastName)
      setValue('billingState', customerState)

      setValue(
        'tenantAbbreviation',
        findTenantByLabel(
          compareEqual(contractID, dispatchSearchInfo?.contractId || '')
            ? dispatchSearchInfo?.tenantAbbreviation || ''
            : ''
        )
      )

      setIsContractInfoLoading(false)
    } catch (e: any) {
      setIsContractInfoLoading(false)
      setReplacementError(e.message)
      console.log(e.message)
    }
  }
  useEffect(() => {
    if (!lastSku) return
    setValue('sku', lastSku, { shouldValidate: true })
    //eslint-disable-next-line
  }, [lastSku])

  useEffect(() => {
    paymentStatus === 'success' && reset(initValues)

    //eslint-disable-next-line
  }, [paymentStatus])
  useEffect(() => {
    const info = dispatchSearchInfo
    if (counter === 0) {
      !firstName &&
        info?.customerName[0] &&
        setValue('firstName', info?.customerName[0])

      !lastName &&
        info?.customerName[1] &&
        setValue('lastName', info.customerName[1])

      !contractID && info?.contractId && setValue('contractID', info.contractId)

      !billingState &&
        info?.billingState &&
        setValue('billingState', findBillingState(info?.billingState || ''))
      setInitialTenantAbbreviation(
        findTenantByLabel(info?.tenantAbbreviation || '')
      )

      !tenantAbbreviation?.id &&
        setValue(
          'tenantAbbreviation',
          findTenantByLabel(info?.tenantAbbreviation || '')
        )
    }
    //eslint-disable-next-line
  }, [formType])

  useEffect(() => {
    const info = dispatchSearchInfo
    if (compareEqual(contractID, info?.contractId || '')) {
      setValue('firstName', info?.customerName[0] || '', {
        shouldValidate: true,
      })
      setValue('lastName', info?.customerName[1] || '', {
        shouldValidate: true,
      })

      setValue(
        'billingState',
        states.find(({ id }) => id === info?.billingState) || emptyItem,
        { shouldValidate: true }
      )
      setValue('tenantAbbreviation', initialTenantAbbreviation)
    }
    //eslint-disable-next-line
  }, [contractID])

  useEffect(() => {
    if (tenantAbbreviation.id === 'PC') {
      setValue('vendor', findVendor(tenantAbbreviation.id))
      setValue('sku', 'HVAC')
    } else {
      setValue('sku', '')
      vendor === 'PC' && setValue('vendor', emptyItem)
    }
    setTenant(tenantAbbreviation.id)
    //eslint-disable-next-line
  }, [tenantAbbreviation])

  useEffect(() => {
    const info = dispatchSearchInfo
    if (compareEqual(contractID, info?.contractId || '')) {
      vendor !== 'PC' &&
        initialTenantAbbreviation.id &&
        setValue('tenantAbbreviation', initialTenantAbbreviation)
    } else {
      vendor !== 'PC' &&
        tenantAbbreviation.id === 'PC' &&
        setValue('tenantAbbreviation', emptyItem)
    }
    //eslint-disable-next-line
  }, [vendor])
  return (
    <div className="flex flex-col items-center sm:block  m-auto lg:m-0 overflow-visible">
      <div className="max-w-sm md:max-w-lg">
        <Controller
          name="contractID"
          control={control}
          render={({ field }) => (
            <div className="w-full sm:w-auto md:w-56 mb-6 relative">
              <Input
                id="retail-payment-form-cid"
                error={errors.contractID?.message || replacementError}
                value={field.value}
                formField
                formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                label={localizedText('CONTRACT_ID_LABEL')}
                className="w-full search-custom-input"
                onChange={(v) => {
                  if (regexDigit.test(v.target.value)) {
                    field.onChange(v)
                  }
                }}
                // alwaysShowClearButton={true}
                onBlur={() => {
                  field.onBlur()
                  if (
                    !compareEqual(
                      contractID,
                      dispatchSearchInfo?.contractId || ''
                    )
                  ) {
                    const fields = [
                      'firstName',
                      'lastName',
                      'billingState',
                      'tenantAbbreviation',
                    ]
                    fields.forEach((v) => resetField(v as any))
                  }
                }}
                disabled={isContractInfoLoading}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' || e.keyCode === 13) {
                    setReplacementInfo()
                  }
                }}
              />

              <div
                id="retail-icon"
                className="absolute overflow-hidden flex justify-center items-center"
                style={{
                  width: '42px',
                  height: '42px',
                  right: '3px',
                  top: '24px',
                }}
              >
                <IconButton
                  color="interactive"
                  shape="rounded"
                  variant="ghost"
                  disabled={!contractID || !!errors.contractID?.message}
                  loading={isContractInfoLoading}
                  size="medium"
                  label={localizedText('SURVEY_DISPATCH_SEARCH_BTN')}
                  icon={<IconSearch size="23" />}
                  onClick={async () => {
                    setReplacementInfo()
                  }}
                />
              </div>
            </div>
          )}
        />
        {Object.keys(init)
          .filter(
            (v) =>
              v !== 'sku' &&
              v !== 'contractID' &&
              v !== 'agreements' &&
              v !== 'amount'
          )
          ?.map((v: any) => {
            if (
              v !== 'billingState' &&
              v !== 'vendor' &&
              v !== 'date' &&
              v !== 'tenantAbbreviation'
            ) {
              return (
                <Controller
                  key={v}
                  name={v}
                  control={control}
                  render={({ field }) => (
                    <Input
                      id={`retail-payment-form-input-${v}`}
                      error={errors?.[v]?.message}
                      value={field.value}
                      formField
                      formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                      label={localizedText(labelsKeys[v])}
                      className="w-full"
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      disabled={true}
                    />
                  )}
                />
              )
            } else if (v === 'billingState' || v === 'vendor') {
              return (
                <Controller
                  key={v}
                  name={v}
                  control={control}
                  render={({ field }) => (
                    <Select
                      id={`retail-payment-form-select-${v}`}
                      autoComplete={false}
                      error={errors[v]?.message}
                      selected={field.value}
                      formField
                      label={localizedText(labelsKeys[v])}
                      className="w-full md:w-56 mb-6"
                      options={getOptions(v)}
                      onSelect={(e) => {
                        if (v === 'vendor') {
                          e.id === 'PC' &&
                            setValue('tenantAbbreviation', findTenant('PC'))
                        }
                        field.onChange(e)
                      }}
                      onBlur={field.onBlur}
                      placeholder="Select an option"
                      disabled={v === 'vendor' ? isVendorsLoading : true}
                    />
                  )}
                />
              )
            } else if (v === 'tenantAbbreviation') {
              return (
                <Controller
                  key={v}
                  name={v}
                  control={control}
                  render={({ field }) => (
                    <Select
                      id={`retail-payment-form-select-${v}`}
                      autoComplete={false}
                      error={errors[v]?.message}
                      selected={field.value}
                      formField
                      label={localizedText(labelsKeys[v])}
                      className="w-full md:w-56 mb-6"
                      options={getOptions(v)}
                      onSelect={field.onChange as SelectHandler<SelectOption>}
                      onBlur={field.onBlur}
                      placeholder="Select an option"
                      disabled={
                        isVendorsLoading ||
                        compareEqual(
                          contractID,
                          dispatchSearchInfo?.contractId || ''
                        )
                      }
                    />
                  )}
                />
              )
            } else {
              return (
                <Controller
                  key={v}
                  name={v}
                  control={control}
                  render={({ field }) => (
                    <DateTimeInput
                      id={`retail-payment-form-date-${v}`}
                      error={errors.date?.message}
                      label={localizedText('OUTRIGHT_PAYMENT_ORDER_DATE_LABEL')}
                      formFieldClassName="w-full sm:w-auto"
                      className="oDate w-full md:w-56 mb-6"
                      selectedDate={field.value}
                      onDateSelect={field.onChange}
                      onBlur={field.onBlur}
                      showMonths={1}
                      maxDate={new Date()}
                      onlyDate
                      datePicker
                      showOutsideMonthDates={true}
                      allowSelectOutsideMonthDates
                      alwaysShowDateClearButton
                    />
                  )}
                />
              )
            }
          })}
      </div>
      {tenantAbbreviation.id === 'PC' ? (
        <div className="max-w-3xl">
          <Controller
            name="sku"
            control={control}
            render={({ field }) => (
              <Input
                disabled={true}
                id="outright-payment-form-sku"
                error={errors.sku?.message || ''}
                value={field.value}
                formField
                formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                label={localizedText('LABEL_SKU')}
                className="w-full"
                onChange={(e) => {
                  e.target.value = e.target.value.toUpperCase()
                  field.onChange(e)
                }}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <Input
                id="outright-payment-form-amount"
                error={errors.amount?.message}
                value={field.value}
                inputMode="decimal"
                startEnhancer={() => <Text>$</Text>}
                formField
                formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                label={localizedText('OUTRIGHT_PAYMENT_AMOUNT_LABEL')}
                className="w-full"
                onChange={field.onChange}
                onBlur={(e) => {
                  setValue('amount', `${+e.target.value}`)
                  field.onBlur()
                }}
              />
            )}
          />
        </div>
      ) : (
        <SkuSearch
          isReplacementInfoLoading={isContractInfoLoading}
          error={errors.sku?.message || ''}
          control={control}
          vendor={vendor}
          params={{ contract_id: contractID }}
          sku={sku}
          resetField={() => setValue('sku', sku)}
        />
      )}

      <div className="mt-8 mb-6" style={{ maxWidth: 1200 }}>
        <ShoppingCart />
      </div>
      {!isShoppingCartEmpty ? (
        <Agreements updateFormState={setAgreements} />
      ) : null}
      {errors.agreements ? (
        <p className="text-error my-4">{localizedText('ERROR_AGREEMENTS')}</p>
      ) : null}
      {total > 0 || tenantAbbreviation.id === 'PC' ? (
        <div className="my-6">
          <PaymentsForm error={errorMsg} submitPaymentRef={submitPaymentRef} />
        </div>
      ) : null}
      <Button
        type="button"
        id="sku-card-form-submit"
        label={localizedText('BUTTON_SUBMIT')}
        className="w-auto flex justify-center lg:inline-block mt-4"
        onClick={() => handleSubmit((data) => submitForm(data, true))()}
        style={{ lineHeight: 1 }}
        disabled={
          tenantAbbreviation.id === 'PC'
            ? false
            : isShoppingCartEmpty || total === 0
        }
        loading={status === 'loading'}
      />

      <CartAvailabilityModal
        callback={() => handleSubmit((data) => submitForm(data))()}
        isOpen={isCartAvailabilityModalOpen}
        toggleModal={setIsCartAvailabilityModalOpen}
      />
    </div>
  )
}

export default OutrightPaymentForm
